import { filter, map } from 'ramda';
import React, { createContext, useContext, useReducer } from 'react';
import { updateObject } from 'utils/helper';

const FormStateContext = createContext();
const FormDispatchContext = createContext();

let initialClientId = 1;

const initialClient = {
	id: initialClientId,
	fullName: '',
	phone: '',
	nipOrCompanyName: '',
};

const initialState = {
	maxAnimatorsAmount: -1, // unlimited
	animators: [initialClient],
	animatorsErrors: [],
	fullName: '',
	phone: '',
	nipId: '',
	marketingConsentPromo: false,
	errors: []
};

const actionsForm = {
	resetState: 'resetState',
	addClient: 'addClient',
	removeClient: 'removeClient',
	updateClient: 'updateClient',
	updateErrors: 'updateErrors',
	updateAnimatorsErrors: 'updateAnimatorsErrors'
};

function mainReducer(state, { type, payload }) {
	switch (type) {
		case actionsForm.resetState: {
			return initialState;
		}
		case actionsForm.addClient: {
			if(state.maxAnimatorsAmount < 0 || state.maxAnimatorsAmount > state.animators.length) {
				return updateObject(state, {
					animators: [...state.animators, { ...initialClient, id: ++initialClientId }],
				});
			} else {
				return state;
			}
		}
		case actionsForm.removeClient: {
			const removeClient = client => client.id !== payload.id;

			return updateObject(state, {
				animators: filter(removeClient, state.animators),
			});
		}
		case actionsForm.updateClient: {
			const updateClient = item => {
				if(item.id === payload.id) {
					return payload;
				} else {
					return item;
				}
			};

			return updateObject(state, {
				animators: map(updateClient, state.animators),
			});
		}
		case actionsForm.updateErrors: {
			return updateObject(state, {
				errors: payload,
			});
		}
		case actionsForm.updateAnimatorsErrors: {
			return updateObject(state, {
				animatorsErrors: payload,
			});
		}
		default: {
			throw new Error(`Unhandled action type: ${type}`);
		}
	}
}

function FormProvider({ maxAnimatorsAmount = -1, children }) {
	const [state, dispatch] = useReducer(mainReducer, { ...initialState, maxAnimatorsAmount });

	return (
		<FormStateContext.Provider value={state}>
			<FormDispatchContext.Provider value={dispatch}>
				{children}
			</FormDispatchContext.Provider>
		</FormStateContext.Provider>
	);
}

function useFormState() {
	const context = useContext(FormStateContext);
	if (context === undefined) {
		throw new Error('useFormState must be used within a FormProvider');
	}
	return context;
}

function useFormDispatch() {
	const context = useContext(FormDispatchContext);
	if (context === undefined) {
		throw new Error('useFormDispatch must be used within a FormProvider');
	}
	return context;
}

export { FormProvider, useFormState, useFormDispatch, actionsForm };
