import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect,useRef, useState } from 'react';

export default function HowItWorkMobileView({ moveToForm }) {
	const { t } = useTranslation();
	const ref = useRef(null);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (ref.current) {
			setHeight(ref.current.clientHeight - 90);
		}
	}, []);

	
	return (
		<div className='flex'>
			<div className='flex-2 relative flex flex-col items-center justify-between pr-20' ref={ref}>
				<div className='absolute' style={{ left: 37, height }}>
					<StaticImage className='object-fill' style={{height: '100%'}} src='../../../../assets/carrier/path_line.png' objectFit='scale-down' alt="" placeholder='tracedSVG' />
				</div>
				<div className='flex items-start mb-10'>
					<div className='mx-auto' style={{ width: 100 }}>
						<StaticImage src='../../../../assets/carrier/path_1_img.png' alt="" placeholder='tracedSVG' />
					</div>
					<div className='w-full'>
						<p className='leading-12 font-bold'>{t('pageCarrier.howItWork.step.1.bold')}</p>
						<p className='leading-12 text-grey-6'>{t('pageCarrier.howItWork.step.1.desc')}</p>
					</div>
					
				</div>

				<div className='flex items-start mb-10'>
					<div className='mx-auto' style={{ width: 100 }}>
						<StaticImage src='../../../../assets/carrier/path_2_img.png' alt="" placeholder='tracedSVG' />
					</div>
					<div className='w-full'>
						<p className='leading-12 mb-05'>{t('pageCarrier.howItWork.step.2.title')}</p>
						<p className='leading-12 text-grey-6'>
							<span>{t('pageCarrier.howItWork.step.2.desc')}</span>
							<button onClick={() => moveToForm()} className='link' tw='!text-green-1'>
								{t('pageCarrier.howItWork.step.2.link')}
							</button>
						</p>
					</div>
					
				</div>

				<div className='flex items-start mb-10'>
					<div className='mx-auto' style={{ width: 100 }}>
						<StaticImage src='../../../../assets/carrier/path_2_img.png' alt="" placeholder='tracedSVG' />
					</div>
					<div className='w-full'>
						<p className='leading-12 mb-05'>{t('pageCarrier.howItWork.step.3.title')}</p>
						<p className='leading-12 text-grey-6'>{t('pageCarrier.howItWork.step.3.desc.1')}</p>
						<Link to='https://apps.apple.com/pl/app/finteq/id1568493252' className='link' tw='!text-green-1'>
							{t('pageCarrier.howItWork.step.3.link.1')}
						</Link>
						<span>{t('pageCarrier.howItWork.step.3.desc.2')}</span>
						<Link to='https://play.google.com/store/apps/details?id=pl.finteq' className='link' tw='!text-green-1'>
							{t('pageCarrier.howItWork.step.3.link.2')}
						</Link>
					</div>
				</div>

				<div className='flex items-start mb-10'>
					<div className='mx-auto' style={{ width: 100 }}>
						<StaticImage src='../../../../assets/carrier/path_3_img.png' alt="" placeholder='tracedSVG' />
					</div>
					<div className='w-full'>
						<p className='leading-12 font-bold mb-05'>{t('pageCarrier.howItWork.step.4.bold')}</p>
						<p className='leading-12 text-grey-6'>
							<span>{t('pageCarrier.howItWork.step.4.desc')}</span>
						</p>
					</div>
					
				</div>
				<div className='flex items-start mb-10'>
					<div className='mx-auto' style={{ width: 100 }}>
						<StaticImage src='../../../../assets/carrier/path_2_img.png' alt="" placeholder='tracedSVG' />
					</div>
					<div className='w-full'>
						<p className='leading-12 mb-05'>{t('pageCarrier.howItWork.step.5.title')}</p>
						<p className='leading-12 text-grey-6'>{t('pageCarrier.howItWork.step.5.desc')}</p>
					</div>
				</div>
				
				<div className='flex items-start'>
					<div className='mx-auto' style={{ width: 100 }}>
						<StaticImage src='../../../../assets/carrier/path_1_img.png' alt="" placeholder='tracedSVG' />
					</div>
					<div className='w-full'>
						<p className='leading-12 font-bold mb-05'>{t('pageCarrier.howItWork.step.6.bold')}</p>
						<p className='leading-12 text-grey-6'>{t('pageCarrier.howItWork.step.6.desc')}</p>
					</div>
				</div>
				
			</div>
		</div>
	);
}
