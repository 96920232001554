import { Collapse } from '@mui/material';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import Button from 'components/material-ui/Button';
import ApplyView from 'components/pages/carrier/view/ApplyView';
import CheckClientView from 'components/pages/carrier/view/CheckClientView';
import HowItWorkDesktopView from 'components/pages/carrier/view/HowItWorkDesktopView';
import HowItWorkMobileView from 'components/pages/carrier/view/HowItWorkMobileView';
import FAQuestions from 'components/pages/pricing/view/FAQuestions';
import { FormProvider } from 'components/pages/promotional/budma/base/FormContext';
import { ENUM_PAGE, ENUM_SOURCE } from 'constants/enums';
import { ENUM_FAQ_CATEGORY } from 'constants/enums';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useClientInfo from 'hooks/useClientInfo';
import React, { useEffect, useRef, useState } from 'react';
import { isMobileScreen } from 'utils/helper';

export default function CarrierPage() {
	const { client } = useClientInfo(ENUM_PAGE.FINTEQ, ENUM_SOURCE.LP2_SUPPLIER_FORM_BUDMA_2000);
	const [isFormVisible, setIsFormVisible] = useState(false);
	const { t } = useTranslation();
	const refApplyView = useRef();
	const refCheckClientView = useRef();
	const youtubeVideo = 'https://youtu.be/pGLVSjTQgBQ';
	const clientViewId = 'profilePhoto';
	const carrierClientView = 'carrierCheckClient';
	const isMobile = isMobileScreen();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleRefToApplyView = () => {
		if(isFormVisible) {
			
			const yOffset = -20; 
			const element = document.getElementById(isMobile ? carrierClientView : clientViewId);
			const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
			window.scrollTo({top: y, behavior: 'smooth'});
			//scrollInto(refCheckClientView);
			return;
		}
		setIsFormVisible(true);
	};

	return (
		<Layout hideLanguage>
			<div className='sm:flex sm:items-center xl:items-start justify-end'>
				<div className='w-full sm:w-50/100 mt-10 sm:ml-30'>
					<div className='layout sm:mr-20'>
						<h1 className='sm:text-shadow pt-20 sm:pt-20 lg:pt-40 xl:pt-80'>{t('pageCarrier.h1')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h1>
						<p className='font-bold mb-05'>{t('pageCarrier.desc.bold.1')}</p>
						<p className='mb-20'>
							{t('pageCarrier.desc.desc.1')}
						</p>
						<p>{t('pageCarrier.desc.desc.2')}</p>
						<ul className='ul-custom-triangle mt-15'>
							<li className='leading-10 text-10'>{t('pageCarrier.desc.li.1')}</li>
							<li className='leading-10 text-10'>{t('pageCarrier.desc.li.2')}</li>
							<li className='leading-10 text-10'>{t('pageCarrier.desc.li.3')}</li>
						</ul>
						<div className='flex items-center justify-center mt-20'>
							<Button href={youtubeVideo} target='_blank'>
								{t('pageCarrier.desc.button')}
							</Button>
						</div>
                        
					</div>
				</div>

				<div className='hidden sm:block sm:w-50/100'>
					<StaticImage src='../../../../assets/supplier/discount-your-invoice.png' alt="" placeholder='tracedSVG' />
				</div>

				<div className='sm:hidden w-full my-15'>
					<StaticImage src='../../../../assets/supplier/mobile-discount-your-invoice.png' alt="" placeholder='tracedSVG' />
				</div>
			</div>

			<div>
				<h2 className="text-center leading-20 mx-auto mb-30 sm:mb-45 pt-30 xl:pt-50">{t('pageCarrier.howItWork.h2')}<span className="text-green-1 font-normal text-shadow-none">_</span></h2>
				<div className='hidden sm:block'>
					<HowItWorkDesktopView moveToForm={handleRefToApplyView} />
				</div>
				<div className='sm:hidden mb-40'>
					<HowItWorkMobileView moveToForm={handleRefToApplyView} />
				</div>
			</div>

			<div className='layout pt-30'>
				<h2 className="text-center leading-20 mx-auto mb-35 sm:mb-45">{t('pageCarrier.howToStart.h2')}<span className="text-green-1 font-normal text-shadow-none">_</span></h2>
				<div id={clientViewId} ref={refCheckClientView} >
					<CheckClientView onRightBtnClick={handleRefToApplyView} />
				</div>
				
			</div>
			<Collapse in={isFormVisible} onEntered={() => handleRefToApplyView()}>
				<FormProvider maxAnimatorsAmount={1}>
					<ApplyView
						hideUserData
						client={client}
						refForward={refApplyView}
						applyTitle={t('pageCarrier.applyClient.h2')}
						applyDesc1={t('pageCarrier.applyClient.desc')}
						marketingConsent={t('app.marketingConsent')}
					/>
				</FormProvider>

				<h2 className='text-center mt-60 mb-50'>{t('pageSupplier.faq.h2')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h2>
				<div className='z-10'>
					<FAQuestions category={ENUM_FAQ_CATEGORY.SUPPLIER_PAGE} />
				</div>
                

				<div className='relative pb-100'>
					<div className='xl:hidden absolute right-0 bottom-0 -z-10'>
						<StaticImage src='../../../assets/supplier/mobile-faq.png' alt="" placeholder='tracedSVG' />
					</div>
					<div className='hidden xl:block absolute right-0 bottom-0 -z-10'>
						<StaticImage src='../../../assets/supplier/faq.png' alt="" placeholder='tracedSVG' />
					</div>
				</div>
			</Collapse>	
			<Footer />
		</Layout>
	);
}
