import Question from 'components/pages/pricing/items/Question';
import useCommonData from 'hooks/useCommonData';
import { QUERY } from 'interfaces/graph';
import React from 'react';

export default function FAQuestions({ category }) {
	const { faq } = useCommonData(QUERY.ALL_FAQ, {
		category
	});

	if (!faq) return null;

	return faq.map(item => (
		<Question key={item.id} title={item.question} subtitle={item.answer}/>
	));
}
