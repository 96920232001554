import { useLazyQuery } from '@apollo/client';
import { Zoom } from '@mui/material';
import IconClose from 'assets/general/close-circle.inline.svg';
import SvgNextRightNormal from 'assets/main/button-next-right-normal.inline.svg';
import Form from 'components/base/Form';
import IconButton from 'components/base/IconButton';
import Button from 'components/material-ui/Button';
import TextField, { isNumeric } from 'components/material-ui/TextField';
import ClientContainer from 'components/pages/financing-for-companies/items/ClientContainer';
import FloatButton from 'components/pages/index/items/FloatButton';
import { useFormik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useSearchParams from 'hooks/useSearchParams';
import { QUERY } from 'interfaces/graph';
import React, { useEffect, useRef, useState } from 'react';

const hasAtLeastNumbersAmount = (string, amount) => {
	let numbersAmount = 0;

	for (const char of string) {
		if (isNumeric(char)) {
			numbersAmount++;
		}
	}
	return numbersAmount >= amount;
};

export default function CheckClientView({ onRightBtnClick, refForward }) {
	const { t } = useTranslation();
	const { language } = useSearchParams();
	const ref = useRef();

	const [getNipOrCompanyName, response] = useLazyQuery(
		QUERY.CHECK_FINTEQ_CLIENT,
		{
			fetchPolicy: 'cache-and-network',
			context: {
				headers: {
					'Accept-Language': language,
				},
			},
		}
	);

	const [isInverted, setInverted] = useState(false);
	const [isSuccessResponse, setSuccessResponse] = useState(false);
	const [nipOrCompanyName, setNipOrCompanyName] = useState(null);

	const formCheckClient = useFormik({
		initialValues: {
			nipOrCompanyName: '',
		},
		initialTouched: {
			nipOrCompanyName: true,
		},
		enableReinitialize: true,
		onSubmit: async ({ nipOrCompanyName }) => {
			const dataToSend = {
				variables: {
					text: nipOrCompanyName,
				},
			};
			await getNipOrCompanyName(dataToSend);
			handleSubmitForm();
		},
	});

	const isNipString = hasAtLeastNumbersAmount(
		formCheckClient.values.nipOrCompanyName,
		5
	);

	useEffect(() => {
		const client = response?.data?.checkFinteqClient;

		if (client && client.nipId && client.fullName) {
			setNipOrCompanyName(isNipString ? client.nipId : client.fullName);
			setSuccessResponse(true);
		} else {
			setNipOrCompanyName(formCheckClient.values.nipOrCompanyName);
		}
	}, [response]);

	const handleSubmitForm = () => {
		setInverted(true);
	};

	const handleReturnToForm = () => {
		setInverted(false);
		formCheckClient.resetForm();
	};

	const handleSubmit = (event) => {
		formCheckClient.handleSubmit(event.preventDefault());
	};

	return (
		<div className="flex flex-col sm:flex-row mb-10" ref={refForward}>
			<ClientContainer
				tw="sm:mr-07 sm:ml-15 mb-35 sm:mb-0"
				title={t('pageCarrier.howToStart.clientCheck.title')}
			>
				<div className="px-12 sm:px-20 xl:px-26">
					<p>{t('pageCarrier.howToStart.clientCheck.desc')}</p>

					<div className="relative h-100">
						<Zoom in={!isInverted}>
							<div className="w-full h-full absolute">
								<div className="flex items-center justify-center relative w-full h-full">
									<Form
										id="CheckClientForm"
										tw="flex flex-col"
										onSubmit={handleSubmit}
									>
										<TextField
											tw="w-full xl:w-200 mx-auto mb-03 mt-15 rounded-10"
											name="nipOrCompanyName"
											form={formCheckClient}
											errors={{ nipOrCompanyName: response?.error?.message }}
											label={t('pageCarrier.howToStart.clientCheck.label')}
											inputProps={{ maxLength: 40 }}
											defaultHelper=" "
											variant="outlined"
										/>

										<Button
											tw="w-80 py-07 px-10"
											data-cy="btnSubmit"
											mode="primary-auto"
											size="large"
											type="submit"
										>
											{t('pageCarrier.howToStart.clientCheck.btnCheck')}
										</Button>
									</Form>
								</div>
							</div>
						</Zoom>

						<Zoom in={isInverted}>
							<div className="w-full h-full absolute">
								<div className="flex items-center justify-center relative w-full h-full">
									<div>
										{isSuccessResponse ? (
											<div>
												<p className="text-11 font-bold text-green-1 text-center">
													{isNipString
														? t(
															'pageCarrier.howToStart.clientCheck.responseNipSuccess',
															{ nip: nipOrCompanyName }
														)
														: t(
															'pageCarrier.howToStart.clientCheck.responseCompanySuccess',
															{ company: nipOrCompanyName }
														)}
												</p>
												<p className="text-11 mt-10 xl:mt-20 text-center">
													<span>
														{t('pageCarrier.howToStart.clientCheck.footer1')}&nbsp;
													</span>
													<span className='text-green-1'>
														{t('pageCarrier.howToStart.clientCheck.footer2')}&nbsp;
													</span>
													<span>
														{t('pageCarrier.howToStart.clientCheck.footer3')}
													</span>
												</p>
											</div>
										) : (
											<div>
												<div className='flex items-center justify-center mb-15'>
													<p className='font-bold'>
														{isNipString ? (
															t('pageCarrier.howToStart.clientCheck.responseNipFail', { nip: nipOrCompanyName })
														) : (
															t('pageCarrier.howToStart.clientCheck.responseCompanyFail', { company: nipOrCompanyName })
														)}
													</p>
													<IconButton tw='ml-05' onClick={handleReturnToForm}>
														<IconClose stroke='#0A9E50' />
													</IconButton>
												</div>
												<Button
													tw='w-90 py-07 px-10'
													data-cy='btnSendClient'
													mode='primary-auto'
													size='large'
													onClick={onRightBtnClick}
												>
													{t('pageCarrier.howToStart.clientCheck.btnSend')}
												</Button>
											</div>
										)}
									</div>
								</div>
							</div>
						</Zoom>
					</div>
				</div>
			</ClientContainer>

			<ClientContainer
				id="carrierCheckClient"
				tw="sm:ml-07 sm:mr-15"
				title={t('pageCarrier.howToStart.clientSupply.title')}
			>
				<p className="px-12 sm:px-20 xl:px-26">
					{t('pageCarrier.howToStart.clientSupply.desc')}
				</p>

				<div className="w-full h-35 flex items-center justify-center">
					<div className="absolute bottom-0 w-full text-center flex items-center justify-center">
						<FloatButton
							style={{ transform: 'translate(-20px,0px)' }}
							center
							onClick={onRightBtnClick}
						>
							{/* <Link to={`${routes[language].FOR_SUPPLIER}?${section}=${searchLangParams.section.declareCandidateAnimator}`}> */}
							<SvgNextRightNormal className="cursor-pointer" />
							{/* </Link> */}
						</FloatButton>
					</div>
				</div>
			</ClientContainer>
		</div>
	);
}
