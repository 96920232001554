import IconClose from 'assets/general/close-circle.inline.svg';
import SvgArrowUp from 'assets/promo/budma/arrow-up.inline.svg';
import IconButton from 'components/base/IconButton';
import TextField from 'components/material-ui/TextField';
import { actionsForm, useFormDispatch } from 'components/pages/promotional/budma/base/FormContext';
import { useFormik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { formatNthString } from 'utils/helper';

export default function ClientItem({ index, qParam, client, errors, ...props }) {
	const { t } = useTranslation();
	const dispatchForm = useFormDispatch();

	const initialClient = (!index && qParam) ? qParam : client.nipOrCompanyName;

	const formClient = useFormik({
		initialValues: {
			id: client.id,
			nipOrCompanyName: initialClient,
			fullName: client.fullName,
			phone: client.phone,
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			dispatchForm({ type: actionsForm.updateClient, payload: values });
		}
	});

	const handleRemoveClient = () => {
		dispatchForm({ type: actionsForm.removeClient, payload: client });
	};

	const handleOnChangeField = event => {
		formClient.handleChange(event);
		formClient.handleSubmit(event);
	};

	return (
		<div>
			<div className='relative border border-grey-D rounded-10 px-15 sm:px-24 py-10 mx-auto w-85/100 sm:w-70/100 xl:w-50/100' {...props}>
				{client.id === 1 && (
					<div className='absolute left-0 top-0 ml-10 sm:ml-15'>
						<SvgArrowUp style={{ marginTop: '-22px' }} />
					</div>
				)}
				{client.id !== 1 && (
					<div className='absolute right-0 top-0'>
						<IconButton data-cy={`btnIconClose-${index}`} onClick={handleRemoveClient}>
							<IconClose stroke='#797979' />
						</IconButton>
					</div>
				)}

				<TextField
					tw='w-full'
					data-cy={`nipOrCompanyName-${index}`}
					name='nipOrCompanyName'
					form={formClient}
					errors={errors}
					label={t('pageCarrier.apply.formLabel.nipId')}
					onChange={handleOnChangeField}
					defaultHelper=' '
				/>

				<TextField
					tw='w-full'
					data-cy={`fullName-${index}`}
					name='fullName'
					form={formClient}
					errors={errors}
					label={t('pageCarrier.apply.formLabel.name')}
					onChange={handleOnChangeField}
					defaultHelper=' '
				/>

				<TextField
					tw='w-full'
					data-cy={`phone-${index}`}
					name='phone'
					form={formClient}
					errors={errors}
					label={t('pageCarrier.apply.formLabel.phone')}
					parsingFunc={formatNthString}
					isNumberWithoutSigns
					isNumberString
					onChange={handleOnChangeField}
					defaultHelper=' '
				/>
			</div>
		</div>
	);
}
