import bg_how_it_work from 'assets/secure-financing/bg-how-it-work.png';
import img_flow_1 from 'assets/secure-financing/flow/1.png';
import img_flow_2 from 'assets/secure-financing/flow/2.png';
import img_flow_3 from 'assets/secure-financing/flow/3.png';
import img_flow_4 from 'assets/secure-financing/flow/4.png';
import img_flow_5 from 'assets/secure-financing/flow/5.png';
import img_flow_6 from 'assets/secure-financing/flow/6.png';
import img_flow_7 from 'assets/secure-financing/flow/7.png';
import img_flow_8 from 'assets/secure-financing/flow/8.png';
import img_flow_9 from 'assets/secure-financing/flow/9.png';
import img_flow_10 from 'assets/secure-financing/flow/10.png';
import img_flow_11 from 'assets/secure-financing/flow/11.png';
import img_flow_12 from 'assets/secure-financing/flow/12.png';
import { Link } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import tw, { css } from 'twin.macro';
import { isTabletMobileScreen } from 'utils/helper';

const cssBgHowItWork = css`
  @media only screen and (min-width: 1680px) {
		${tw`flex items-center justify-center flex-col bg-left bg-no-repeat pb-150 -mb-80`}
		background-image: url(${bg_how_it_work});
  }
`;

export default function HowItWorkDesktopView({ moveToForm }) {
	const { t } = useTranslation();

	const isSm = isTabletMobileScreen();

	return (
		<div
			style={ isSm ? { width: '150vw', transform: 'scale(0.7)', marginLeft: '-25vw', marginTop: '-20vw', marginBottom: '-15vw' } : {}}
			className='flex flex-col mx-auto items-center justify-center'
			css={[ cssBgHowItWork ]}
		>
			<div className='ml-120'>
				<div className='flex'>
					<img style={{  marginLeft: '-2px', marginBottom: '-1px' }} src={img_flow_1} alt="" />
					<div className='relative'>
						<img src={img_flow_2} alt="" />
						<div className='absolute left-0 top-0'>
							<p className='font-bold text-12 mb-05'>{t('pageCarrier.howItWork.step.1.bold')}</p>
							<p className='w-4/5 text-grey-6'>{t('pageCarrier.howItWork.step.1.desc')}</p>
						</div>
					</div>
				</div>
				<div className='flex'>
					<div className='relative'>
						<img src={img_flow_3} alt="" />
						<div className='absolute right-0 top-0 w-2/3'>
							<p className='text-12 mb-05'>{t('pageCarrier.howItWork.step.2.title')}</p>
							<p className='text-grey-6'>
								<span>{t('pageCarrier.howItWork.step.2.desc')}</span>
								<button onClick={() => moveToForm()} className='link' tw='!text-green-1'>
									{t('pageCarrier.howItWork.step.2.link')}
								</button>
							</p>
						</div>
					</div>
					<img src={img_flow_4} alt="" />
				</div>
				<div className='flex'>
					<img src={img_flow_5} alt="" />
					<div className='relative'>
						<img style={{ marginLeft: '-1px' }} src={img_flow_6} alt="" />
						<div className='absolute left-0 top-0 w-full'>
							<p className='text-12 mb-05'>{t('pageCarrier.howItWork.step.3.title')}</p>
							<p className='pr-10 text-grey-6'>{t('pageCarrier.howItWork.step.3.desc.1')}</p>
							<Link to='https://apps.apple.com/pl/app/finteq/id1568493252' target="_blank" className='link' tw='!text-green-1'>
								{t('pageCarrier.howItWork.step.3.link.1')}
							</Link>
							<span>{t('pageCarrier.howItWork.step.3.desc.2')}</span>
							<Link to='https://play.google.com/store/apps/details?id=pl.finteq' target="_blank" className='link' tw='!text-green-1'>
								{t('pageCarrier.howItWork.step.3.link.2')}
							</Link>
						</div>
					</div>
				</div>
				<div className='flex'>
					<div className='relative'>
						<img src={img_flow_7} alt="" />
						<div className='absolute left-0 top-0 -ml-15'>
							<p className='font-bold text-12 mb-05'>{t('pageCarrier.howItWork.step.4.bold')}</p>
							<p className='text-grey-6'>
								<span>{t('pageCarrier.howItWork.step.4.desc')}</span>
							</p>
						</div>
					</div>
					<img src={img_flow_8} alt="" />
				</div>
				<div className='flex'>
					<img src={img_flow_9} alt="" />
					<div className='relative'>
						<img src={img_flow_10} alt="" />
						<div className='absolute left-0 top-0'>
							<p className='text-12 mb-05'>{t('pageCarrier.howItWork.step.5.title')}</p>
							<p className='w-2/3 text-grey-6'>{t('pageCarrier.howItWork.step.5.desc')}</p>
						</div>
					</div>
				</div>
				<div className='flex'>
					<img src={img_flow_11} alt="" />
					<div className='relative'>
						<img src={img_flow_12} alt="" />
						<div className='absolute left-0 top-0'>
							<p className='font-bold text-12 mb-05'>{t('pageCarrier.howItWork.step.6.bold')}</p>
							<p className='text-grey-6'>{t('pageCarrier.howItWork.step.6.desc')}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
