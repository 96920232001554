import { Collapse } from '@mui/material';
import { useLocation } from '@reach/router';
import bg_applicant_details from 'assets/promo/budma/bg-applicant-details.png';
import Button from 'components/material-ui/Button';
import ApplicantItem from 'components/pages/carrier/items/ApplicantItem';
import ClientItem from 'components/pages/carrier/items/ClientItem';
import { actionsForm, useFormDispatch, useFormState } from 'components/pages/promotional/budma/base/FormContext';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useSearchParams, { getSearchParam, getSearchSecondParam } from 'hooks/useSearchParams';
import React, { useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import tw, { css } from 'twin.macro';
import { isBrowser, scrollInto } from 'utils/helper';

const cssBgApplicantDetails = css`
  ${tw`relative bg-center`}
  background-image: url(${bg_applicant_details});
`;

export default function ApplyView({ hideUserData, client, applyTitle, applyDesc1, applyDesc2, marketingConsent, refForward, ...props }) {
	const { t } = useTranslation();
	const dispatchForm = useFormDispatch();
	const { animators, maxAnimatorsAmount, animatorsErrors } = useFormState();
	const { searchLangParams } = useSearchParams();
	const location = useLocation();

	useEffect(() => {
		const searchParam = getSearchParam(location.search);
		const searchParamFiltered = searchParam.replace('?q', '');

		if(searchParamFiltered === searchLangParams.section.declareCandidateAnimator) {
			scrollInto(refForward);
		}
	}, []);

	useEffect(() => {
		const shouldRefToContext = animatorsErrors.find(item => item.nipOrCompanyName || item.fullName);
		if(shouldRefToContext) {
			scrollInto(refForward);
		}
	}, [animatorsErrors]);

	const transitions = useTransition(animators, item => item.id, {
		initial: { transform: 'translate3d(0vw,0,0) scale(1)', height: '18rem', opacity: 1 },
		from: { transform: 'translate3d(-100vw,0,0) scale(0.8)', height: '0rem', opacity: 0 },
		enter: { transform: 'translate3d(0vw,0,0) scale(1)', height: '18rem', opacity: 1 },
		leave: { transform: 'translate3d(-100vw,0,0) scale(0.8)', height: '0rem', opacity: 0 }
	});

	const handleAddClient = () => {
		dispatchForm({ type: actionsForm.addClient });
	};

	return (
		<div tw='relative mt-20 sm:mt-40' {...props}>
			<div className='layout text-center'>
				<h2 ref={refForward} className='pt-20'>{applyTitle}<span className='text-green-1 font-normal text-shadow-none'>_</span></h2>
				<p className='w-80/100 sm:w-70/100 xl:w-50/100 text-left mx-auto pt-10'>{applyDesc1}</p>
				<p className='w-80/100 sm:w-70/100 xl:w-50/100 text-left mx-auto mb-14'>{applyDesc2}</p>

				<Collapse in={true}>
					{transitions.map(({ item, props }, key) => (
						<animated.div key={item.id} style={props}>
							<ClientItem index={key} qParam={getSearchSecondParam(location.search)} client={item} errors={animatorsErrors[key]} />
						</animated.div>
					))}

					{(maxAnimatorsAmount < 0 || maxAnimatorsAmount > animators.length) && (
						<div className='w-fit mx-auto mt-05'>
							<Button data-cy='btnNextCandidate' tw='bg-green-6 bg-opacity-25' size='large' mode='outline' onClick={handleAddClient}>
								{t('pageCarrier.apply.formLabel.submitNext')}
							</Button>
						</div>
					)}
				</Collapse>
			</div>

			<div css={[ isBrowser && window.innerWidth < 2000 && cssBgApplicantDetails ]}>
				<div className='layout text-center pt-40'>
					<h2>{t('pageCarrier.userData.h2')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h2>
					{!hideUserData && (
						<>
							<p className='w-80/100 sm:w-70/100 xl:w-50/100 text-left mx-auto pt-07'>{t('pageCarrier.userData.desc.1')}</p>
							<p className='w-80/100 sm:w-70/100 xl:w-50/100 text-left mx-auto'>{t('pageCarrier.userData.desc.2')}</p>
						</>
					)}
					<ApplicantItem client={client} marketingConsent={marketingConsent} />
				</div>
			</div>
		</div>
	);
}
