import SvgArrowUp from 'assets/promo/budma/arrow-up.inline.svg';
import Form from 'components/base/Form';
import ButtonIconLeft from 'components/material-ui/ButtonIconLeft';
import Checkbox from 'components/material-ui/Checkbox';
import FormControlLabel from 'components/material-ui/FormControlLabel';
import ModalCode from 'components/material-ui/ModalCode';
import TextCodeFor from 'components/material-ui/TextCodeFor';
import TextController from 'components/material-ui/TextController';
import TextField from 'components/material-ui/TextField';
import { actionsForm, useFormDispatch, useFormState } from 'components/pages/promotional/budma/base/FormContext';
import { useFormik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useMutation from 'hooks/useMutation';
import { MUTATION } from 'interfaces/graph';
import React, { useState } from 'react';
import { formatNthString, isTabletMobileScreen } from 'utils/helper';

export default function ApplicantItem({ client, marketingConsent, ...props }) {
	const { t } = useTranslation();
	const dispatchForm = useFormDispatch();
	const { animators, fullName, phone, nipId, errors } = useFormState();

	const [isOsModal, setOsModal] = useState(false);

	const [sendForm, mutationRes] = useMutation(MUTATION.INVITE_ANIMATOR, {
		onSuccess: () => {
			handleOsModalShow();
		},
		onErrors: e => {
			let errorsArray = [];

			for (const animator of animators) {
				errorsArray.push({ nipOrCompanyName: '', fullName: '' });
			}

			for (let i = 0; i < animators.length; i++) {
				if (!animators[i].nipOrCompanyName) {
					errorsArray[i].nipOrCompanyName = 'Pole wymagane.';
				}
				if (!animators[i].fullName) {
					errorsArray[i].fullName = 'Pole wymagane.';
				} else if (animators[i].fullName?.split(' ')?.length < 2) {
					errorsArray[i].fullName = 'Pole musi zawierać imię i nazwisko.';
				}
			}

			dispatchForm({ type: actionsForm.updateErrors, payload: e });
			dispatchForm({ type: actionsForm.updateAnimatorsErrors, payload: errorsArray });
		}
	});

	const formApplicant = useFormik({
		initialValues: {
			nipId: nipId,
			fullName: fullName,
			phone: phone,
			marketingConsent: false
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			if (mutationRes.loading) return;

			const dataToSend = {
				variables: {
					...client,
					...values,
					animators: JSON.stringify(animators),
					marketingConsentContent: marketingConsent
				}
			};
			sendForm(dataToSend);
		}
	});

	const handleOsModalShow = () => {
		setOsModal(true);
	};

	const handleOsModalClose = () => {
		dispatchForm({ type: actionsForm.resetState });
		formApplicant.resetForm();
		setOsModal(false);
	};

	const handleSubmit = event => {
		formApplicant.handleSubmit(event.preventDefault());
	};

	return (
		<Form id={client.sourceId} onSubmit={handleSubmit}>
			<ModalCode
				isOpen={isOsModal}
				onClose={handleOsModalClose}
			>
				<TextController index={0} delay={3800} className="font-code-pro text-09 sm:text-11 p-05 sm:p-10">
					<TextCodeFor showCaretDuringDelay delay={1200}>{t('pageCarrier.modal.osSupplier.row.1', { fullName: formApplicant.values.fullName })}</TextCodeFor>
					<TextCodeFor delayCaretHide={1200}>{t('pageCarrier.modal.osSupplier.row.2')}</TextCodeFor>
					<TextCodeFor delayCaretHide={1200}>{t('pageCarrier.modal.osSupplier.row.3')}</TextCodeFor>
					<TextCodeFor delayCaretHide={1200}>{t('pageCarrier.modal.osSupplier.row.4')}</TextCodeFor>
					<TextCodeFor delayCaretHide={12000} className="mt-20" onAnimationEnd={handleOsModalClose}>
						{t(isTabletMobileScreen() ? 'modal.os.endMobile' : 'modal.os.end')}
					</TextCodeFor>
				</TextController>
			</ModalCode>

			<div className="relative border border-grey-D rounded-10 px-15 sm:px-24 py-10 my-15 mx-auto w-85/100 sm:w-70/100 xl:w-50/100" {...props}>
				<div className="absolute left-0 top-0 ml-10 sm:ml-15">
					<SvgArrowUp style={{ marginTop: '-22px' }}/>
				</div>

				<TextField
					tw="w-full"
					name="fullName"
					form={formApplicant}
					errors={errors}
					label={t('pageCarrier.userData.formLabel.name')}
				/>

				<TextField
					tw="w-full mt-15"
					name="phone"
					form={formApplicant}
					errors={errors}
					label={t('pageCarrier.userData.formLabel.phone')}
					parsingFunc={formatNthString}
					isNumberWithoutSigns
					isNumberString
				/>

				<TextField
					tw="w-full my-15"
					name="nipId"
					form={formApplicant}
					errors={errors}
					label={t('pageCarrier.userData.formLabel.nip')}
				/>
			</div>

			<FormControlLabel
				tw="flex items-start w-90/100 sm:w-75/100 xl:w-50/100 mx-auto"
				label={<p className="text-left text-09 leading-12 sm:text-10 sm:leading-14 mt-03">{marketingConsent}</p>}
				labelPlacement="end"
				control={
					<Checkbox
						tw="mr-05"
						name="marketingConsent"
						checked={formApplicant.values.marketingConsent}
						onChange={formApplicant.handleChange}
						errors={errors}
					/>
				}
			/>

			<div className="w-fit mx-auto mt-20">
				<ButtonIconLeft name="send-applicant" loading={false} type="submit">{t('pageCarrier.userData.formLabel.send')}</ButtonIconLeft>
			</div>
		</Form>
	);
}
