import Collapse from '@mui/material/Collapse';
import SvgSquareMinus from 'assets/pricing/square-minus.inline.svg';
import SvgSquarePlus from 'assets/pricing/square-plus.inline.svg';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import tw, { css } from 'twin.macro';

const cssQuestion = css`
	${tw`my-10 mx-15 xl:mx-auto rounded-12 bg-light px-15 py-07 border-2 border-grey-E`};
	
	a {
    color: -webkit-link;
    text-decoration: underline;
		cursor: pointer;
	}
`;

export default function Question({ title, subtitle }) {
	const [isOpenQuestion, setOpenQuestion] = useState(false);

	const handleToggleQuestion = () => {
		setOpenQuestion(prev => !prev);
	};

	return (
		<div style={{ maxWidth: '55rem' }} css={[ cssQuestion ]}>
			<div className='flex items-center justify-between pointer' onClick={handleToggleQuestion}>
				<p className='font-bold'>{parse(title)}</p>
				{isOpenQuestion ? <SvgSquareMinus /> : <SvgSquarePlus />}
			</div>
			<Collapse in={isOpenQuestion}>
				<p className='pr-40 leading-13 mt-05 mb-03'>{parse(subtitle)}</p>
			</Collapse>
		</div>
	);
}
